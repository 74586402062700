<template>
  <div>
    <!-- begin:: Content Head -->
    <KTSubheader v-bind:title="'Users'" />
    <!-- end:: Content Head -->
    <b-row>
      <b-col xxl="6">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark"
                >Edit User</span
              >
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-0 pb-3">
            <div class="tab-content">
              <b-form @submit="onSubmit" v-if="show">
                <b-form-row>
                  <b-col md="6">
                    <b-form-group
                      id="first-name-group"
                      label="First Name:"
                      label-for="first-name-input"
                    >
                      <b-form-input
                        id="first-name-input"
                        v-model="form.firstName"
                        required
                        placeholder="Enter first name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      id="last-name-group"
                      label="Last Name:"
                      label-for="last-name-input"
                    >
                      <b-form-input
                        id="last-name-input"
                        v-model="form.lastName"
                        required
                        placeholder="Enter last name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="6">
                    <b-form-group
                      id="email-group"
                      label="Email:"
                      label-for="email-input"
                    >
                      <b-form-input
                        id="email-input"
                        v-model="form.email"
                        required
                        type="email"
                        placeholder="Enter email"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      id="role-group"
                      label="Role:"
                      label-for="role-input"
                    >
                      <b-form-select
                        id="role-input"
                        v-model="form.roleId"
                        :options="roles"
                        required
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="6">
                    <b-form-group
                      id="phone-group"
                      label="Phone:"
                      label-for="phone-input"
                    >
                      <b-form-input
                        id="phone-input"
                        v-model="form.phone"
                        required
                        placeholder="Enter phone"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      id="birthday-group"
                      label="Birthday:"
                      label-for="birthday-input"
                    >
                      <b-form-datepicker
                        id="birthday-input"
                        v-model="form.birthday"
                        class="mb-2"
                      ></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <router-link :to="{ name: 'users' }">
                  <b-button type="button" variant="secondary">Cancel</b-button>
                </router-link>
                <b-button type="submit" variant="primary" class="ml-3"
                  >Submit</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </b-col>
      <b-col xxl="6">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark"
                >Edit Password</span
              >
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-0 pb-3">
            <div class="tab-content">
              <b-form @submit="onChangePassword" v-if="show">
                <b-form-row>
                  <b-col md="6">
                    <b-form-group
                      id="password-group"
                      label="Password:"
                      label-for="password-input"
                    >
                      <b-form-input
                        id="password-input"
                        v-model="changePasswordForm.password"
                        type="password"
                        required
                        placeholder="Enter Password"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="6">
                    <b-form-group
                      id="password-confirmation-group"
                      label="Password Confirmation:"
                      label-for="password-confirmation-input"
                    >
                      <b-form-input
                        id="password-confirmation-input"
                        v-model="changePasswordForm.passwordConfirmation"
                        type="password"
                        required
                        placeholder="Confirm Password"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-button type="submit" variant="primary"
                  >Change Password</b-button
                >
              </b-form>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import {
  GET_USER,
  UPDATE_USER_PASSWORD,
  UPDATE_USER
} from "@/core/services/store/user.module.js";

export default {
  name: "edit-user",
  components: {
    KTSubheader
  },
  data: () => ({
    id: null,
    form: {
      firstName: "",
      lastName: "",
      email: "",
      roleId: 2,
      phone: "",
      birthday: ""
    },
    changePasswordForm: {
      password: null,
      passwordConfirmation: null
    },
    roles: [
      { value: 1, text: "Admin" },
      { value: 2, text: "Agent" }
    ],
    show: true
  }),
  async mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      await this.$store.dispatch(GET_USER, this.id);
      delete this.user.password;
      this.form = this.user;
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(UPDATE_USER, { id: this.id, ...this.form })
        .then(data => {
          this.$router.push("/users");
        })
        .catch(error => {});
    },
    onChangePassword(evt) {
      evt.preventDefault();
      if (
        this.changePasswordForm.password !==
        this.changePasswordForm.passwordConfirmation
      ) {
        this.$store.dispatch("showSnackbar", {
          message: "Password does not match",
          color: "error"
        });
        return;
      }

      this.$store
        .dispatch(UPDATE_USER_PASSWORD, {
          id: this.id,
          ...this.changePasswordForm
        })
        .then(data => {
          this.$router.push("/users");
        })
        .catch(error => {});
    }
  }
};
</script>
